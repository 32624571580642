import * as React from 'react';

const EventbriteAppMarketplaceLogoSvg = (
    props: React.SVGProps<SVGSVGElement>,
) => (
    <svg
        width="326"
        height="24"
        viewBox="0 0 326 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_104_617)">
            <g clipPath="url(#clip1_104_617)">
                <path
                    d="M47.6398 5.95801L45.0478 13.3233H44.9398L42.3538 5.95801H37.8178L42.2458 17.8143H47.3938L51.8218 5.95801H47.6398Z"
                    fill="#FF5E30"
                />
                <path
                    d="M72.2461 5.63477C70.4581 5.63477 69.0421 6.43118 68.4061 7.73656V5.95812H64.0861V17.8204H68.4061V11.994C68.4061 10.2096 68.9941 9.04195 70.4581 9.04195C71.7661 9.04195 72.2221 9.89225 72.2221 11.515V17.8144H76.5421V10.3713C76.5421 7.89824 75.4981 5.63477 72.2461 5.63477Z"
                    fill="#FF5E30"
                />
                <path
                    d="M105.186 8.05992V5.95812H100.866V17.8204H105.186V12.3952C105.186 10.4012 106.176 9.53896 107.772 9.53896C108.6 9.53896 109.506 9.70064 110.094 10.0479V5.97609C109.8 5.73657 109.158 5.521 108.438 5.521C106.866 5.521 105.612 6.73058 105.186 8.05992Z"
                    fill="#FF5E30"
                />
                <path
                    d="M115.134 5.95801H110.814V17.8203H115.134V5.95801Z"
                    fill="#FF5E30"
                />
                <path
                    d="M112.974 5.12571C114.322 5.12571 115.416 4.03457 115.416 2.68859C115.416 1.3426 114.322 0.251465 112.974 0.251465C111.625 0.251465 110.532 1.3426 110.532 2.68859C110.532 4.03457 111.625 5.12571 112.974 5.12571Z"
                    fill="#FF5E30"
                />
                <path
                    d="M121.734 13.2635V8.92813H124.83V5.95208H121.734V2.68262H119.142C119.142 4.491 117.672 5.95208 115.86 5.95208V8.92813H117.462V14.509C117.462 17.3054 119.352 18.1257 121.356 18.1257C122.982 18.1257 124.02 17.7245 124.932 17.0599V14.2694C124.212 14.5629 123.624 14.6706 123.096 14.6706C122.214 14.6766 121.734 14.2754 121.734 13.2635Z"
                    fill="#FF5E30"
                />
                <path
                    d="M84.12 14.6766H84.108C83.232 14.6766 82.758 14.2754 82.758 13.2694V8.93411H85.854V5.95208H82.758V2.68262H80.166C80.166 4.491 78.696 5.95208 76.884 5.95208H76.878V8.92813H78.48V14.509C78.48 17.3054 80.37 18.1257 82.374 18.1257C84 18.1257 85.038 17.7245 85.95 17.0599V14.2694C85.236 14.5629 84.648 14.6766 84.12 14.6766Z"
                    fill="#FF5E30"
                />
                <path
                    d="M32.6341 5.64062C28.9321 5.64062 26.1601 8.32925 26.1601 12.0778C26.1601 15.8562 28.8781 18.1436 32.6881 18.1436C36.1501 18.1436 38.2201 16.3412 38.7121 13.5927H35.1661C34.7641 14.5209 34.0201 14.9221 32.8501 14.9221C31.5421 14.9221 30.4801 14.2334 30.3721 12.7664H38.7121V11.5688C38.7061 8.3532 36.6001 5.64062 32.6341 5.64062ZM30.4201 10.6945C30.5821 9.57476 31.1941 8.72446 32.5801 8.72446C33.8581 8.72446 34.5541 9.62865 34.6081 10.6945H30.4201Z"
                    fill="#FF5E30"
                />
                <path
                    d="M57.2457 5.64062C53.5437 5.64062 50.7717 8.32925 50.7717 12.0778C50.7717 15.8562 53.4897 18.1436 57.2997 18.1436C60.7617 18.1436 62.8317 16.3412 63.3237 13.5927H59.7777C59.3757 14.5209 58.6317 14.9221 57.4617 14.9221C56.1537 14.9221 55.0917 14.2334 54.9837 12.7664H63.3237V11.5688C63.3237 8.3532 61.2177 5.64062 57.2457 5.64062ZM55.0317 10.6945C55.1937 9.57476 55.8057 8.72446 57.1917 8.72446C58.4697 8.72446 59.1657 9.62865 59.2197 10.6945H55.0317Z"
                    fill="#FF5E30"
                />
                <path
                    d="M138 11.5688C138 8.3532 135.894 5.64062 131.922 5.64062C128.22 5.64062 125.448 8.32925 125.448 12.0778C125.448 15.8562 128.166 18.1436 131.976 18.1436C135.438 18.1436 137.508 16.3412 138 13.5927H134.454C134.052 14.5209 133.308 14.9221 132.138 14.9221C130.83 14.9221 129.768 14.2334 129.66 12.7664H138L138 11.5688ZM129.714 10.6945C129.876 9.57476 130.488 8.72446 131.874 8.72446C133.152 8.72446 133.848 9.62865 133.902 10.6945H129.714Z"
                    fill="#FF5E30"
                />
                <path
                    d="M94.9197 5.61684C92.9217 5.61684 91.5837 6.57492 90.9957 7.9881V1.04199H86.6757V17.8144H90.0117L90.7557 15.7665C91.4217 17.2037 92.9157 18.1378 94.8357 18.1378C98.4117 18.1378 100.2 15.3953 100.2 11.8803C100.2 8.38331 98.4117 5.61684 94.9197 5.61684ZM93.4017 14.8025C91.9317 14.8025 91.0017 13.6827 91.0017 11.9522V11.7965C91.0017 10.0899 91.9377 8.97612 93.4017 8.97612C95.0817 8.97612 95.8557 10.1737 95.8557 11.8803C95.8557 13.6049 95.0817 14.8025 93.4017 14.8025Z"
                    fill="#FF5E30"
                />
                <path
                    d="M18.168 16.1078L10.206 9.91617C10.074 9.81438 10.212 9.61078 10.356 9.69462L13.464 11.4311C14.874 12.2216 16.656 11.7605 17.508 10.3952C18.408 8.9521 17.934 7.05389 16.458 6.2036L11.898 3.57485C11.754 3.49102 11.862 3.26946 12.018 3.33533L14.112 4.17365C14.118 4.17365 14.166 4.19162 14.184 4.1976C14.406 4.27545 14.646 4.31737 14.892 4.31737C16.026 4.31737 16.962 3.41916 17.04 2.3533C17.142 0.964073 16.086 0 14.82 0H5.136C3.894 0 2.844 1.01197 2.862 2.2515C2.868 2.91018 3.168 3.497 3.63 3.8982C3.978 4.20359 5.15399 5.13772 5.71199 5.58682C5.81399 5.66467 5.754 5.82634 5.628 5.82634H3.69C1.65 5.83832 0 7.49101 0 9.52694C0 10.5569 0.419999 11.485 1.104 12.1617L12.33 22.8084C13.122 23.5449 14.19 24 15.36 24C17.82 24 19.812 22.012 19.812 19.5569C19.806 18.1677 19.17 16.9222 18.168 16.1078Z"
                    fill="#FF5E30"
                />
                <path
                    d="M322.405 14.4671L325.305 14.7167C324.786 17.0015 322.962 18.2687 320.332 18.2687C317.164 18.2687 314.879 16.3487 314.879 13.1807C314.879 10.0511 317.202 7.80469 320.293 7.80469C323.615 7.80469 325.381 10.0703 325.381 12.7583V13.7759H318.393C318.489 14.9855 319.391 15.5807 320.466 15.5807C321.445 15.5807 322.079 15.2351 322.405 14.4671ZM318.45 12.0287H321.945C321.906 11.1455 321.311 10.3775 320.255 10.3775C319.084 10.3775 318.585 11.0879 318.45 12.0287Z"
                    fill="#211D1A"
                />
                <path
                    d="M303.592 13.0271C303.592 9.89749 305.896 7.80469 309.025 7.80469C312.02 7.80469 313.691 9.74389 313.921 12.1247L310.964 12.3167C310.715 11.0879 310.12 10.5119 309.083 10.5119C307.988 10.5119 307.22 11.2607 307.22 13.0271C307.22 14.8127 307.988 15.5423 309.083 15.5423C310.12 15.5423 310.715 14.9471 310.964 13.7183L313.921 13.9487C313.691 16.3487 312.02 18.2687 309.025 18.2687C305.896 18.2687 303.592 16.1951 303.592 13.0271Z"
                    fill="#211D1A"
                />
                <path
                    d="M301.797 11.7023V15.0815C301.797 15.5999 302.008 15.8111 302.392 15.8111C302.68 15.8111 302.911 15.7727 303.199 15.6575V17.5775C302.68 17.9423 301.97 18.2303 300.952 18.2303C299.723 18.2303 298.763 17.5967 298.437 16.4639C297.842 17.6159 296.709 18.2303 295.135 18.2303C293.195 18.2303 291.928 17.2127 291.928 15.5231C291.928 13.6031 293.311 12.7967 295.461 12.4127L298.245 11.9327V11.7791C298.245 10.9535 297.88 10.4735 296.997 10.4735C296.21 10.4735 295.807 10.9919 295.653 11.7215L292.312 11.4911C292.619 9.32149 294.251 7.80469 297.227 7.80469C299.858 7.80469 301.797 8.93749 301.797 11.7023ZM298.245 13.8527V13.6991L296.786 13.9679C295.922 14.1407 295.403 14.4095 295.403 15.0623C295.403 15.5807 295.807 15.9071 296.402 15.9071C297.419 15.9071 298.245 15.1583 298.245 13.8527Z"
                    fill="#211D1A"
                />
                <path
                    d="M286.935 3.96484H290.545V18H286.935V3.96484Z"
                    fill="#211D1A"
                />
                <path
                    d="M277.833 16.3103V21.5711H274.223V8.07349H277.833V9.78229C278.313 8.59189 279.426 7.80469 281.097 7.80469C284.015 7.80469 285.513 10.1087 285.513 13.0271C285.513 15.9647 284.015 18.2687 281.097 18.2687C279.426 18.2687 278.313 17.4623 277.833 16.3103ZM277.833 12.9695V13.1039C277.833 14.5439 278.62 15.4847 279.829 15.4847C281.231 15.4847 281.884 14.4863 281.884 13.0271C281.884 11.6063 281.231 10.6079 279.829 10.6079C278.62 10.6079 277.833 11.5487 277.833 12.9695Z"
                    fill="#211D1A"
                />
                <path
                    d="M266.619 15.2353V10.5697H265.275V8.34253L266.005 8.22733C267.003 8.07373 267.329 7.53613 267.56 6.61452L267.905 5.32812H270.171V8.07372H272.763V10.5697H270.171V14.1985C270.171 15.0433 270.574 15.3697 271.304 15.3697C271.765 15.3697 272.245 15.2737 272.84 15.0433V17.3857C272.091 17.9425 271.227 18.2689 269.864 18.2689C268.193 18.2689 266.619 17.5777 266.619 15.2353Z"
                    fill="#211D1A"
                />
                <path
                    d="M261.824 14.4671L264.723 14.7167C264.205 17.0015 262.381 18.2687 259.751 18.2687C256.583 18.2687 254.298 16.3487 254.298 13.1807C254.298 10.0511 256.621 7.80469 259.712 7.80469C263.034 7.80469 264.8 10.0703 264.8 12.7583V13.7759H257.811C257.907 14.9855 258.81 15.5807 259.885 15.5807C260.864 15.5807 261.498 15.2351 261.824 14.4671ZM257.869 12.0287H261.363C261.325 11.1455 260.73 10.3775 259.674 10.3775C258.503 10.3775 258.003 11.0879 257.869 12.0287Z"
                    fill="#211D1A"
                />
                <path
                    d="M246.82 14.8512V18H243.21V3.96484H246.82V11.4912L250.218 8.07364H254.462L250.353 11.9712L254.596 18H250.545L247.684 13.968L246.82 14.8512Z"
                    fill="#211D1A"
                />
                <path
                    d="M242.097 8.16949L241.886 11.5871C241.387 11.2991 240.849 11.1647 240.158 11.1647C238.833 11.1647 238.008 11.7791 238.008 13.4495V17.9999H234.398V8.07349H238.008V9.82069C238.372 8.70709 239.409 7.80469 240.715 7.80469C241.329 7.80469 241.867 7.97749 242.097 8.16949Z"
                    fill="#211D1A"
                />
                <path
                    d="M231.953 11.7023V15.0815C231.953 15.5999 232.164 15.8111 232.548 15.8111C232.836 15.8111 233.067 15.7727 233.355 15.6575V17.5775C232.836 17.9423 232.126 18.2303 231.108 18.2303C229.88 18.2303 228.92 17.5967 228.593 16.4639C227.998 17.6159 226.865 18.2303 225.291 18.2303C223.352 18.2303 222.084 17.2127 222.084 15.5231C222.084 13.6031 223.467 12.7967 225.617 12.4127L228.401 11.9327V11.7791C228.401 10.9535 228.036 10.4735 227.153 10.4735C226.366 10.4735 225.963 10.9919 225.809 11.7215L222.468 11.4911C222.776 9.32149 224.408 7.80469 227.384 7.80469C230.014 7.80469 231.953 8.93749 231.953 11.7023ZM228.401 13.8527V13.6991L226.942 13.9679C226.078 14.1407 225.56 14.4095 225.56 15.0623C225.56 15.5807 225.963 15.9071 226.558 15.9071C227.576 15.9071 228.401 15.1583 228.401 13.8527Z"
                    fill="#211D1A"
                />
                <path
                    d="M214.237 18H210.992L208.477 8.13124H208.4V18H204.925V3.96484H210.781L212.855 12.7968H212.912L214.89 3.96484H220.612V18H216.829V8.13124H216.752L214.237 18Z"
                    fill="#211D1A"
                />
                <path
                    d="M191.714 16.3103V21.5711H188.104V8.07349H191.714V9.78229C192.194 8.59189 193.307 7.80469 194.978 7.80469C197.896 7.80469 199.394 10.1087 199.394 13.0271C199.394 15.9647 197.896 18.2687 194.978 18.2687C193.307 18.2687 192.194 17.4623 191.714 16.3103ZM191.714 12.9695V13.1039C191.714 14.5439 192.501 15.4847 193.711 15.4847C195.112 15.4847 195.765 14.4863 195.765 13.0271C195.765 11.6063 195.112 10.6079 193.711 10.6079C192.501 10.6079 191.714 11.5487 191.714 12.9695Z"
                    fill="#211D1A"
                />
                <path
                    d="M179.001 16.3103V21.5711H175.392V8.07349H179.001V9.78229C179.481 8.59189 180.595 7.80469 182.265 7.80469C185.184 7.80469 186.681 10.1087 186.681 13.0271C186.681 15.9647 185.184 18.2687 182.265 18.2687C180.595 18.2687 179.481 17.4623 179.001 16.3103ZM179.001 12.9695V13.1039C179.001 14.5439 179.788 15.4847 180.998 15.4847C182.4 15.4847 183.052 14.4863 183.052 13.0271C183.052 11.6063 182.4 10.6079 180.998 10.6079C179.788 10.6079 179.001 11.5487 179.001 12.9695Z"
                    fill="#211D1A"
                />
                <path
                    d="M164.587 3.96484H168.83L174.418 18H170.386L169.33 15.2736H163.858L162.802 18H159L164.587 3.96484ZM166.546 8.30404L164.952 12.4512H168.235L166.642 8.30404H166.546Z"
                    fill="#211D1A"
                />
                <rect x="148" y="2" width="1" height="18" fill="#D8D8D8" />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_104_617">
                <rect width="326" height="24" fill="white" />
            </clipPath>
            <clipPath id="clip1_104_617">
                <rect width="326" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

EventbriteAppMarketplaceLogoSvg.displayName = 'EventbriteAppMarketplaceLogoSvg';
export default EventbriteAppMarketplaceLogoSvg;
